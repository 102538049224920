.input {
  width: 100%;
  border: 1px solid white;
  margin: 5px 0;
  color: white;
  height: 39px;
  border-radius: 4px;
  background: transparent;
  outline: none;
  text-align: center;
  font-size: 0.9rem;
  font-family: Inter, sans-serif;
  font-weight: 500;
}

.input::placeholder {
  color: white;
}
